<template>
    <v-container fluid class="white shadow1 rounded">
        <div class="custom-form">
            <v-row dense justify="center" class="pb-4">
                <v-col cols="auto" md="4">
                    <v-row no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                        <v-col cols="3">Select serial</v-col>
                        <v-col cols="9">
                            <div class="select_wrap">
                                <input
                                    v-model="serial"
                                    @keyup.enter="handleEnter"
                                    type="search"
                                    class="serial_search"
                                    v-fSelect
                                />
                                <div class="drop" v-if="lodash.size(filteredSerial)">
                                    <div
                                        v-for="(item, index) in filteredSerial"
                                        :key="index"
                                    >
                                        <div
                                            v-if="index < 10"
                                            @click="handleInputClick(item)"
                                            class="drop_item"
                                        >
                                            {{ item.serial }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <v-combobox 
                                dense
                                outlined
                                hide-details
                                v-model="serial"
                                @focus="getSerials()"
                                :items="serials"
                                :loading="loadingSerials"
                                @keydown.enter="(e) => handleEnter(e)"
                                item-text="display_text"
                                item-value="id"
                            >
                            </v-combobox> -->
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto" md="1" class="d-flex align-center">
                    <v-btn type="button" @click="getSerialLedger" class="text_bg_fave" :loading="loadingSerials">Search</v-btn>
                    <v-btn type="button" @click="()=>{
                        filteredSerial=[];
                        serial=null;
                    }" color="red" style="color: #fff;margin-left:5px;">Clear</v-btn>
                </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row v-if="show">
                <v-col cols="12" id="printContent" class="d_table">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Purchase Date</th>
                                    <th>Purchase Invoice</th>
                                    <th>Supplier Details</th>
                                    <th>Sale Date</th>
                                    <th>Sale Invoice</th>
                                    <th>Customer Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(product, ind) in $store.getters['serial/ledgers']" :key="ind">
                                    <td>{{ lodash.get(product, 'purchase.created_at') | formatDateTime('DD-MM-YYYY h:mm a') }}</td>
                                    <td>
                                        <v-icon
                                            small
                                            @click="$router.push(`/purchase-invoice/${product.purchase_id}`)"
                                            color="success"
                                            target="_blank"
                                            v-if="product.purchase_id"
                                        >
                                            mdi-file
                                        </v-icon>
                                    </td>
                                    <td>
                                        <template v-if="lodash.get(product, 'purchase.supplier')">
                                            <div><strong>Name: </strong> {{ lodash.get(product, 'purchase.supplier.name') }}</div>
                                            <div><strong>Address: </strong> {{ lodash.get(product, 'purchase.supplier.address') }}</div>
                                            <div><strong>Phone: </strong> {{ lodash.get(product, 'purchase.supplier.phone') }}</div>
                                        </template>
                                    </td>
                                    <td>{{ lodash.get(product, 'sales.created_at') | formatDateTime('DD-MM-YYYY h:mm a') }}</td>
                                    <td>
                                        <v-icon
                                            small
                                            @click="$router.push(`/sale-invoice/${product.sales_id}`)"
                                            color="success"
                                            target="_blank"
                                            v-if="product.sales_id"
                                        >
                                            mdi-file
                                        </v-icon>
                                    </td>
                                    <td>
                                        <template v-if="lodash.get(product, 'sales.customer')">
                                            <div><strong>Name: </strong> {{ lodash.get(product, 'sales.customer.name') }}</div>
                                            <div><strong>Address: </strong> {{ lodash.get(product, 'sales.customer.address') }}</div>
                                            <div><strong>Phone: </strong> {{ lodash.get(product, 'sales.customer.phone') }}</div>
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <div v-else class="no_result">
                <div class="img"></div>
            </div>
        </div>
    </v-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
export default {
    name: 'ProductLedger',

    data: () => ({
        filter: {
            dateFrom: new Date().toISOString().substr(0, 10),
            dateTo: new Date().toISOString().substr(0, 10),
            serial: null,
        },
        loading: false,
        show: false,
        serials: [],
        filteredSerial: [],
        loadingSerials: false,
        serial: null,
        serialSearch: null,
        timeout: null,
    }),
    filters: {
        formatDateTime(dt, format) {
            return dt == '' || dt == null ? '' : moment(dt).format(format);
        }
    },

    watch: {
        serial(serial) {
            if(serial == undefined) return;
            let srl = []
            for(let i = 0; i < this.serials.length; i++) {
                if (this.serials[i]) {
                    if (String(this.serials[i].serial).toLowerCase().indexOf(String(serial).toLowerCase()) > -1) {
                        srl.push(this.serials[i])
                    }
                }
            }
            this.filteredSerial = srl;
        }
    },
    async created() {
        await this.getSerials()
    },
    methods: {
        handleEnter(e) {
            if (this.filteredSerial[0]) {
                this.serial = this.filteredSerial[0].serial
                this.getSerialLedger()
            } else {
                if (e?.keyCode == 13) {
                    this.getSerialLedger()
                }
            }
        },
        handleInputClick(item) {
            this.serial=item.serial
            this.filteredSerial = []
            this.getSerialLedger()
        },
        handleFocusOut(item) {
            this.serial=item.display_text
        },
        async getSerials(){
            this.loadingSerials = true;
            await this.$store.dispatch('serial/getSerial', {
                // serial: this.serial,
                // limit: 20
            })
            this.loadingSerials = false;
            
            this.serials = this.$store.getters['serial/serials'];
        },
        async getSerialLedger() {
            if(this.serial == null || this.serial == '' || this.serial == undefined) {
                this.$store.dispatch('snackbar/error', 'Select a product');
                return;
            }
            this.filteredSerial = [];
            this.loading = true;
            this.loadingSerials = true;
            await this.$store.dispatch('serial/getLedgers', {
                dateFrom: this.filter.dateFrom,
                dateTo: this.filter.dateTo,
                serial: this.serial,
            })
            console.log(this.$store.getters['serial/ledgers']);
            this.loadingSerials = false;
            this.show = true;
            this.loading = false;
            // this.serial = null;
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: 3px !important;
    }
   .d_table > thead > tr:last-child > th {
        font-size: 11px;
        padding: 0px 1px;
        border: 1px solid #ddd !important;
        text-align: center;
   }
   .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border: 1px solid #ddd;
   }
   .d_table table > tbody > tr > td {
        // height: 20px !important;
        padding: 0px 5px !important;
        font-size: 12px !important;
        border: 1px solid #ddd !important;
   }
   .select_wrap {
    position: relative;
    padding: 0 10px;
    width: 100%;
    .serial_search {
        width: 100%;
        padding: 5px 10px;
        border: 1px solid #ddd;
        &:focus {
            outline: 0;
        }
    }
    .drop {
        position: absolute;
        background: #fff;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        & .drop_item {
            padding: 5px 10px;
            user-select: none;
            cursor: pointer;
            &:not(:last-child) {
                border-bottom: 1px solid #ddd;
            }
            &:hover {
                background: #eee;
            }
        }
    }
}
</style>